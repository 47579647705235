import React, { useState } from 'react';
import Container from '../../../remitbee/components/container/Container';
import Typography from '../../../remitbee/components/typography/Typography';
import Spacing from '../../../remitbee/styles/spacing/Spacing';
import CodeVerificationInput from '../../design-system/CodeVerificationInput/CodeVerificationInput';
import Button from '../../../remitbee/components/button/Button';
import { snackbar } from '../../../remitbee/components/snackbar/SnackBar';
import { useDispatch } from 'react-redux'
import {
    generateTwoFactorCodeV2, sendCustomerResetPasswordLink, setPhoneValidated, signInEmail, verifyTwoFactorCodeV2
} from '../../../redux/auth/actions';
import { setAccountTypeBusiness, setAccountTypePersonal, businessDataQuery, setPromoStatus } from '../../../redux/business/actions';
import { useRouter } from 'next/router';
import theme from '../../../remitbee/theme/Theme';
import Box from '../../../remitbee/components/box/Box';
import Divider from '../../../remitbee/components/divider/Divider';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { BrowserLogger, BrowserLoggerOperation } from '../../../settings/browser-logger-v2';

const VerificationCodeScreen = ({ phoneNumber, state, setState, startTimer, enableResendingTimer, t }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const dispatch = useDispatch();
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const { cus_unique_id:cusUniqId } = useSelector((state) => state.customer);

    const handleVerifyCodeSubmit = async (event) => {
        if (event) event.preventDefault();
        const { cus_unique_id } = state;
        setState({ ...state, loading: true });

        BrowserLogger.log('Customer clicked verify code button', { 
            action_from: 'VerificationCodeScreen',
            function_name: 'handleVerifyCodeSubmit',
            operation: BrowserLoggerOperation.LOGIN_VERIFICARION,
            cus_unique_id,
        });

        const { success, errorMessage, data } = await dispatch(verifyTwoFactorCodeV2({ cus_unique_id: cus_unique_id || cusUniqId, code: verificationCode }));
        if (success) {
            dispatch(setPhoneValidated({ isPhoneValidated: true, cp_session_token: data?.verifyTwoFactorCodeV2?.cp_session_token }));
            let redirectUrl = '/dashboard';
            try {
                const isBusiness = data?.verifyTwoFactorCodeV2?.personalInformation?.is_business_ac;
                const isPersonal = data?.verifyTwoFactorCodeV2?.personalInformation?.is_personal_ac;
                const lastActiveAccount = data?.verifyTwoFactorCodeV2?.personalInformation?.last_active_ac;

                if (isBusiness === '1' && isPersonal === '1') {
                    if (lastActiveAccount === 'PERSONAL') dispatch(setAccountTypePersonal())
                    else {
                        setAccountTypeBusiness();
                        redirectUrl = '/business-account/dashboard';
                    }
                } else if (isBusiness === '1') {
                    setAccountTypeBusiness();
                    redirectUrl = '/business-account/dashboard'
                } else if (isPersonal === '1') setAccountTypePersonal();
            } catch (err) {
                BrowserLogger.error(err, {
                    action_from: 'VerificationCodeScreen',
                    function_name: 'handleVerifyCodeSubmit',
                    operation: BrowserLoggerOperation.LOGIN_VERIFICARION,
                    cus_unique_id,
                });
            }
            router.push(redirectUrl);
        } else {
            snackbar.error(errorMessage, 3000)
            setState({ ...state, errorMessage });
        }
    }



    const resendCode = async () => {
        if (!state?.cus_unique_id) {
            snackbar.error('Something went wrong. Please refresh the page and retry again', 3000);
        }

        BrowserLogger.log('Customer clicked resend code button', { 
            action_from: 'VerificationCodeScreen',
            function_name: 'resendCode',
            operation: BrowserLoggerOperation.LOGIN_VERIFICARION,
        });

        const { success, errorMessage } = await generateTwoFactorCodeV2({ cus_unique_id: state?.cus_unique_id || cusUniqId });
        if (success) {
            snackbar.success('code resend success', 3000);
            startTimer(60);
        } else if (errorMessage) {
            if (errorMessage?.indexOf('Please wait for') > -1) {
                const timer = parseInt(errorMessage.slice(16, 19));
                if (!isNaN(timer) && timer <= 240) {
                    startTimer(timer);
                }
            }
            snackbar.error(errorMessage, 3000);

            BrowserLogger.error(errorMessage, {
                action_from: 'VerificationCodeScreen',
                function_name: 'resendCode',
                operation: BrowserLoggerOperation.LOGIN_VERIFICARION,
            });
        }
    }

    return (
        <>{
            !isMobile && <Container style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
                type='extrasmall'
            >
                <div>
                    <Typography id='enter_your_verification_code' variant='h1' weight='semibold' color={theme.palette.primary.navy}>
                        {t('login:enter_your_verification_code')}
                    </Typography>
                    <Spacing variant='betweenSections' />
                    <Typography id='verfication_code_description_sms'>
                        {t('login:verfication_code_description_sms', { phoneNumber })}
                    </Typography>
                    <Spacing variant='betweenSections' />
                    <form onSubmit={handleVerifyCodeSubmit}>
                        <CodeVerificationInput
                            length={6} onComplete={(code) => setVerificationCode(code)}
                        />
                        <Spacing variant='betweenSections' />
                        <Button id='verify_code' fullWidth disabled={state?.loading || verificationCode?.length !== 6}
                            loading={state?.loading}>{t('login:verify_code')}</Button>
                    </form>
                    <Spacing variant='betweenCards' />
                    <Box display='flex' justifyContent='center'>
                        <Typography id='dont_receive_code' variant='body2' color={theme.palette.input.body} >
                            {t('login:dont_receive_code')}&nbsp;
                        </Typography>
                        <Typography id='resend_code' color={theme.palette.primary.blue} variant='body2' link onClick={resendCode}>{t('login:resend_code')}&nbsp;</Typography>
                        <Typography id='timer' variant='body2' color={theme.palette.input.body} >
                            {enableResendingTimer > 0 && `in ${enableResendingTimer} sec`}
                        </Typography>
                    </Box>
                </div>
            </Container>
        }
            {
                isMobile &&
                    <Spacing variant='containerVerticalPadding'>
                        <div>
                            <Typography id='enter_your_verification_code' variant='h2' weight='semibold' color={theme.palette.primary.navy}>
                                {t('login:enter_your_verification_code')}
                            </Typography>
                            <Spacing variant='betweenHeaderToSubheader' />
                            <Box>
                                <Divider />
                            </Box>
                            <Spacing variant='betweenSections' />
                            <Typography id='verfication_code_description_sms' >
                                {t('login:verfication_code_description_sms', { phoneNumber })}
                            </Typography>
                            <Spacing variant='betweenSections' />
                            <form onSubmit={handleVerifyCodeSubmit}>
                                <CodeVerificationInput
                                    length={6} onComplete={(code) => setVerificationCode(code)}
                                />
                                <Spacing variant='contentToCtaSmall' />
                                <Box display='flex' justifyContent='center'>
                                    <Typography id='dont_receive_code' variant='body2' color={theme.palette.input.body} >
                                        {t('login:dont_receive_code')}&nbsp;
                                    </Typography>
                                    <Typography id='resend_code' color={theme.palette.primary.blue} variant='body2' link onClick={resendCode}>{t('login:resend_code')}&nbsp;</Typography>
                                    <Typography id='timer' variant='body2' color={theme.palette.input.body} >
                                        {enableResendingTimer > 0 && `in ${enableResendingTimer} sec`}
                                    </Typography>
                                </Box>
                                <div style={{
                                    position: 'absolute', bottom: '32px', left: '24px', right: '24px'
                                }}>
                                    <Button id='verify_code' fullWidth disabled={state.loading || verificationCode?.length !== 6}
                                        loading={state?.loading}>{t('login:verify_code')}</Button>
                                    <Spacing variant='betweenCards' />
                                </div>

                            </form>
                        </div>
                    </Spacing>
            }
        </>
    );
}

export default VerificationCodeScreen;