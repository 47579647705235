import React, { useEffect, useState } from 'react';
import Container from '../../../remitbee/components/container/Container';
import Typography from '../../../remitbee/components/typography/Typography';
import Spacing from '../../../remitbee/styles/spacing/Spacing';
import Box from '../../../remitbee/components/box/Box';
import Divider from '../../../remitbee/components/divider/Divider';
import Button from '../../../remitbee/components/button/Button';
import { ACTIONS, Analytics } from '../../../settings/analytics';
import { snackbar } from '../../../remitbee/components/snackbar/SnackBar';
import { sendCustomerResetPasswordLink } from '../../../redux/auth/actions';
import { useRouter } from 'next/router';
import theme from '../../../remitbee/theme/Theme';
import Input from '../../../remitbee/components/input/Input';
import classes from './ForgotPassword.module.scss';
import { emailRegex } from '../../../shared/regex';
import { useMediaQuery } from '@mui/material';
import { BrowserLogger, BrowserLoggerOperation } from '../../../settings/browser-logger-v2';

const ForgotPassword = ({
    state,
    setState,
    isProd,
    token,
    handleReCaptchaVerify,
    setResetEmail,
    propsToBePassed,
    t
}) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

    const onChange = ({ target }) => {
        const email = target?.value;
        setEmail(email);
        let error = '';
        
        //const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
        if (email === '') {
            error = 'This field is required';
        } else if (!emailRegex?.test(email)) {
            error = 'Invalid email address';
        }
    
        setError(error);
    };

    useEffect(() => {
        if (propsToBePassed.getValues('email')) {
            setEmail(propsToBePassed.getValues('email'));
        }
    },[propsToBePassed.getValues('email')])
    

    const onForgotPasswordSubmit = async () => {
        setLoading(true);
        if(!error){
            setResetEmail(email);
        if ( isProd) {
            if (!email?.includes('remittest')) {
                setSubmitting(false);
                handleReCaptchaVerify();
                setState({
                    ...state,
                    errorMessage: t('common:validation.recaptcha'),
                    loading: false,
                });
            }
        }

        BrowserLogger.log('Customer clicked send reset link button', { 
            action_from: 'ForgotPassword',
            function_name: 'onForgotPasswordSubmit',
            operation: BrowserLoggerOperation.FORGOT_PASSWORD,
            cus_email: email,
        });

        const { success, data, errorMessage } = await sendCustomerResetPasswordLink({ email, recaptcha: token });
        setState({ ...state, loading: false });
        setSubmitting(false);
        if (success) {
            snackbar.success(`${data?.sendCustomerResetPasswordLink}`, 10000);
            Analytics.track(ACTIONS.FORGOT_PASSWORD, { email });
            setState({ ...state, recoveryPasswordEmailSent: true });
        } else {
            snackbar.error(errorMessage, 10000)
            setState({ ...state, errorMessage });

            BrowserLogger.error(errorMessage, { 
                action_from: 'ForgotPassword',
                function_name: 'onForgotPasswordSubmit',
                operation: BrowserLoggerOperation.FORGOT_PASSWORD,
            });
        }
        }
        
        setLoading(false);
    }


    return (
        <>
            {
                !isMobile && <Container style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                    type='extrasmall'
                >
                    <div>
                        <Typography id='enter_your_email_to_reset' variant='h1' weight='semibold' color='#31517A' align='center'>
                            {t('login:enter_your_email_to_reset')}
                        </Typography>
                        <Spacing variant='betweenHeaderToSubheader' />
                        <Box>
                            <Divider />
                            <Spacing variant='betweenSections' />
                        </Box>
                        <Typography id='reset_password_guide' color='#626680' >
                            {t('login:reset_password_guide')}
                        </Typography>
                        <Spacing variant='betweenSectionToInputFields' />
                        <Input
                            id='email'
                            label='Email address'
                            fullWidth
                            placeholder='Enter your email address'
                            required
                            error={error}
                            value={email}
                            onChange={onChange}
                        />
                        <Spacing variant='betweenSectionToInputFields' />
                        <Button fullWidth id='send_reset_link' onClick={()=> onForgotPasswordSubmit()} loading={loading || submitting}>{t('login:send_reset_link')}</Button>
                        <Spacing variant='betweenSectionsSmall' />
                        <Button fullWidth variant='outlined' id='back_to_login' type='submit' onClick={() => router.push('/login')}>{t('login:back_to_login')}</Button>
                    </div>
                </Container>
            }
            {
                isMobile && <Spacing variant='containerVerticalPadding' >
                        <Box className={classes['rb-forgotPassword-mobileView']}>
                            <Box>
                            <Typography id='enter_your_email_to_reset' variant='h2' weight='semibold' color='#31517A'>
                                {t('login:enter_your_email_to_reset')}
                            </Typography>
                            <Spacing variant='betweenCards' />
                            <Typography id='reset_password_guide' color='#626680' >
                                {t('login:reset_password_guide')}
                            </Typography>
                            <Spacing variant='betweenSectionToInputFields' />
                            <Input
                                id='email'
                                label='Email address'
                                fullWidth
                                placeholder='Enter your email address'
                                required
                                error={error}
                                value={email}
                                onChange={onChange}
                            />
                            </Box>
                            <Box>
                                <Button fullWidth id='send_reset_link' onClick={()=> onForgotPasswordSubmit()} loading={loading || submitting}>{t('login:send_reset_link')}</Button>
                                <Spacing variant='betweenButtons' />
                                <Button fullWidth variant='outlined' id='back_to_login' onClick={() => router.push('/login')}>{t('login:back_to_login')}</Button>
                            </Box>
                        </Box>
                </Spacing>
            }
        </>
    )
}

export default ForgotPassword