import React, { useState, useEffect } from 'react';
import Container from '../../../remitbee/components/container/Container';
import Typography from '../../../remitbee/components/typography/Typography';
import Box from '../../../remitbee/components/box/Box';
import Spacing from '../../../remitbee/styles/spacing/Spacing';
import Divider from '../../../remitbee/components/divider/Divider';
import FormFields from '../../sendMoneyV2/RecipientDetails/RecipientForm/FormFields/FormFields';
import Button from '../../../remitbee/components/button/Button';
import { createHash, sanitizePath } from '../../../shared/utility';
import { useRouter } from 'next/router';
import { ACTIONS, Analytics } from '../../../settings/analytics';
import { setAccountTypeBusiness, setAccountTypePersonal, setPromoStatus } from '../../../redux/business/actions';
import { getClientBusinessDialogCookie } from '../../../shared/cookie-handler';
import PropTypes from 'prop-types';
import { signInEmail } from '../../../redux/auth/actions';
import { useDispatch } from 'react-redux'
import Alert from '../../../remitbee/components/alert/Alert';
import { IconButton, InputAdornment, } from '@mui/material';
import TogglePassword from '../../design-system/TogglePassword/TogglePassword';
import theme from '../../../remitbee/theme/Theme';
import { useMediaQuery } from '@mui/material';
import classes from '../LoginWizard.module.scss';
import { setCustomerUniqId } from '../../../redux/customer/actions';
import { BrowserLogger, BrowserLoggerOperation } from '../../../settings/browser-logger-v2';
import { getMoEngage } from '../../../settings/moengage/moengage-service';

const Login = ({
    setState,
    state,
    propsToBePassed,
    handleSubmit,
    isProd,
    token,
    handleReCaptchaVerify,
    setPhoneNumber,
    startTimer,
    t
}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const [promoRateStatus, setPromoRateStatus] = useState(null);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            propsToBePassed.setValue('saveDevice', true);
        }
    };

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state?.showPassword });
    }

    const handleClickForgetPassword = () => {
        setState({ ...state, forgotPassword: true });
    }

    useEffect(() => {
        const errorDetails: any = {
        };
        errorDetails.variant = 'success';
        errorDetails.message = "Your password has been updated.";
        if (router.query.passwordUpdation === 'success') setLoginError(errorDetails);
    }, [])

    useEffect(() => {
        if (promoRateStatus) {
            getPromoInfo();
        }
    }, [promoRateStatus]);

    const FORM_LOGIN_INFORMATION = [
        {
            id: 'email',
            field_value: 'email',
            field_label: t('login-signup-form:email_address.label'),
            is_required: '1',
            section_width: 'FULL',
            placeholder: t('login-signup-form:email_address.placeholder'),
            regex: /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        },
        {
            id: 'password',
            field_value: 'password',
            field_label: t('login-signup-form:password.label'),
            is_required: '1',
            section_width: 'FULL',
            placeholder: t('login-signup-form:password.placeholder'),
            type: state?.showPassword ? 'text' : 'password',
            skipValidation: true,
            endAdornment: <InputAdornment position="end">
                <IconButton
                    // tabIndex="-1"
                    id='toggle-password'
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={(e) => e.preventDefault()}>
                    <TogglePassword showPassword={state?.showPassword} />
                </IconButton>
            </InputAdornment>,
            onClick: handleClickForgetPassword,
            isForgetPassword: true
        }
    ]

    const getPromoInfo = async () => {
        const showPromo = Boolean(await getClientBusinessDialogCookie());
        dispatch(setPromoStatus((promoRateStatus === 'ACTIVE' || promoRateStatus === 'AVAILABLE') && !showPromo));
    }

    const submitData = async (values: any) => {
        try {
            setLoading(true);
            const errorDetails: any = {};
            const { email, password, saveDevice } = values;
            setState({ ...state, loading: true, errorMessage: null });
            if (!token && isProd) {
                if (!email.includes('remittest')) {
                    setSubmitting(false);
                    handleReCaptchaVerify();
                    setState({
                        ...state,
                        errorMessage: t('common:validation.recaptcha'),
                        loading: false,
                    });
                }
            }
    
            BrowserLogger.log('Customer clicked login button', { 
                action_from: 'Login',
                function_name: 'submitData',
                operation: BrowserLoggerOperation.LOGIN,
                cus_email: email,
                saveDevice,
            });
    
            const encryptedPassword = createHash(password);
            const { success, data, errorMessage } = await dispatch(signInEmail(email, encryptedPassword, token, saveDevice));
            setSubmitting(false);
    
            if (success) {
                const moe = await getMoEngage();
                setPhoneNumber(data?.signInEmail?.personalInformation?.cus_phone1);
                dispatch(setCustomerUniqId(data?.signInEmail?.personalInformation?.cus_unique_id));
                let redirectUrl = '/dashboard';
                const isBusiness = data?.signInEmail?.personalInformation?.is_business_ac;
                const isPersonal = data?.signInEmail?.personalInformation?.is_personal_ac;
                const lastActiveAccount = data?.signInEmail?.personalInformation?.last_active_ac;
    
                if(moe) {
                    moe?.add_unique_user_id(data?.signInEmail?.personalInformation?.cus_unique_id);
                    moe?.add_email(email)
                    moe?.add_first_name(data?.signInEmail?.personalInformation?.cus_firstname)
                    moe?.add_last_name(data?.signInEmail?.personalInformation?.cus_lastname)
                }
                
    
                if (isBusiness === '1' && isPersonal === '1') {
                    if (lastActiveAccount === 'PERSONAL') dispatch(setAccountTypePersonal())
                    else {
                        dispatch(setAccountTypeBusiness());
                        redirectUrl = '/business-account/dashboard';
                    }
                } else if (isBusiness === '1') {
                    dispatch(setAccountTypeBusiness());
                    redirectUrl = '/business-account/dashboard'
                } else if (isPersonal === '1') dispatch(setAccountTypePersonal());
                if (saveDevice && email !== "") {
                    localStorage.setItem('email', email);
                }
                localStorage.setItem('saveDevice', saveDevice);
                const { cus_2fa_preference, cus_unique_id, cus_phone1, cus_phone_validation } = data?.signInEmail?.personalInformation;
                const { is_2fa_active } = data?.signInEmail;
    
                // Update stored business info
                if (data?.signInEmail?.businessInformation) {
                    setPromoRateStatus(data?.signInEmail?.businessInformation?.promo_rate_status);
                }
                // Log login succesfull personal/business data in all scenario (coming from customer and business tables)
                Analytics.track(ACTIONS.LOGIN, data.signInEmail.personalInformation);
    
                BrowserLogger.setUser({
                    email,
                    id: cus_unique_id,
                    business_unique_id: isBusiness === '1' && lastActiveAccount === 'BUSINESS' ? 
                        data?.signInEmail?.businessInformation?.business_unique_id : null,
                });
    
                if (!cus_phone1) {
                    setState({ ...state, loading: false, isCusPhoneValid: false, isLegacyVerifyFlow: false });
                } else if (cus_phone_validation === '0') {
                    setState({ ...state, loading: false, isCusPhoneValid: false, isLegacyVerifyFlow: true });
                    router.push('/verify-phone');
                } else if (is_2fa_active === '1' && cus_2fa_preference && data?.signInEmail?.isDeviceRegistered === false) {
                    setState({ ...state, loading: false, cus_2fa_preference, cus_unique_id });
                    startTimer(60);
                } else {
                    const { redirectTo } = router.query;
                    if (redirectTo) {
                        const sanitizedPath = sanitizePath(redirectTo.toString(), redirectUrl);
                        router.push(sanitizedPath);
                    } else {
                        router.push(redirectUrl);
                    }
                }
            }
            else {
                // Need to clear previous recaptcha or else API will throw an error for validated recaptcha.
                handleReCaptchaVerify();
                setState({
                    ...state,
                    errorMessage,
                    loading: false,
                    loginAttemptsCounter: state.loginAttemptsCounter + 1
                });
                errorDetails.variant = 'negative';
                errorDetails.message = errorMessage;
                setLoginError(errorDetails);
                setLoading(false);
    
                BrowserLogger.error(errorMessage, { 
                    action_from: 'Login',
                    function_name: 'submitData',
                    operation: BrowserLoggerOperation.LOGIN,
                    cus_email: email,
                    saveDevice,
                    loginAttemptsCounter: state?.loginAttemptsCounter + 1,
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container className={classes['rb-login-container']}>
            <Spacing variant='containerVerticalPadding'>
                <div>
                    <Container type={'extrasmall'}>
                        {
                            !isMobile && <>
                                <Typography id='welcome_back' variant='h1' weight='semibold' color={theme.palette.primary.navy} align='center'>
                                    {t('login:welcome_back')}
                                </Typography>
                                <Spacing variant='betweenSectionsSmall' />
                                <Box>
                                    <Divider />
                                    <Spacing variant='betweenSections' />
                                </Box>
                            </>
                        }
                        {
                            isMobile && <>
                                <Typography id='welcome_back' variant='h2' weight='semibold' color={theme.palette.primary.navy}>
                                    {t('login:welcome_back')}
                                </Typography>
                                <Spacing variant='betweenSectionToInputFields' />
                            </>
                        }
                        {
                            loginError && <Box>
                                <Alert
                                    id="transfer-detail-compliance-notification"
                                    type="alert"
                                    showCloseIcon={false}
                                    variant={loginError.variant}
                                    message={loginError.message}
                                />
                                <Spacing variant='betweenSections' />
                            </Box>
                        }
                        <form onSubmit={handleSubmit(submitData)}>
                            <FormFields fields={FORM_LOGIN_INFORMATION} {...propsToBePassed} />
                            <>
                                <Spacing variant='betweenSectionsSmall'></Spacing>
                                <Typography id='forgot-password' variant='label3' color={theme.palette.primary.blue} link onClick={handleClickForgetPassword}>{'Forgot password?'}
                                </Typography>
                            </>
                            <Spacing variant='betweenSectionToInputFields' />
                            <Box display='flex'>
                                <input
                                    id='checkbox'
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    style={{ width: '20px', height: '20px', marginRight: '10px', border: `1px solid ${theme.palette.border.regular}` }}
                                />
                                <Box display='flex' alignItems='center'>
                                    <Typography id='remember_device' variant='body3' >{t('login:remember_device')}</Typography>
                                </Box>
                            </Box>

                            <Spacing variant='betweenSections' />
                            <Button fullWidth id='save-changes' type='submit' loading={loading || submitting}>Login</Button>
                            <Spacing variant={isMobile ? 'titleToCard' : 'betweenSections'} />

                            <Box display='flex' justifyContent='center'>
                                <Typography id='dont_have_account' variant='body2' color={theme.palette.input.body} >
                                    {t('login:dont_have_account')}&nbsp;
                                </Typography>
                                <Typography id='signup' color={theme.palette.primary.blue} variant='body2' link onClick={() => router.push('/signup')}>{t('login:signup')}</Typography>
                            </Box>
                        </form>
                    </Container>
                </div>
            </Spacing>
        </Container>
    );
};

Login.propTypes = {
    i18n: PropTypes.object,
    router: PropTypes.object,
    namespacesRequired: PropTypes.array,
    setCsrfToken: PropTypes.func,
    signInEmail: PropTypes.func,
    t: PropTypes.func,
    tReady: PropTypes.bool,
    verifyTwoFactorCodeV2: PropTypes.func,
    setPhoneValidated: PropTypes.func,
};


export default Login; 