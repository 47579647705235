import React from "react";
import classes from "./Drawer.module.scss";
import Container from "../../../remitbee/components/container/Container";
import Logo from "../../../remitbee/components/logo/Logo";
import Spacing from "../../../remitbee/styles/spacing/Spacing";
import Typography from "../../../remitbee/components/typography/Typography";

const Drawer = () => {
    return (
        <div
            className={classes['rb-loginWizard-drawer-container']}
            // Background URL was crashing on module, that's why it was moved here on in-line style
            style={{
                background: 'linear-gradient(180deg, rgba(17, 96, 247, 0.90) 0%, rgba(13, 59, 148, 0.80) 100%) , url(/signup_banner.png) center bottom no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <Container style={{
                display: 'flex',
                alignItems: 'center',
                height: '100vh '
            }}>
                <div className={classes['rb-loginWizard-drawer-content']} >
                    <Logo variant="secondary" height={48} width={181} />
                    <Spacing variant="contentToCta" />
                    <Typography weight="bold" style={{
                        fontSize: '40px',
                        // fontFamily: 'Poppins'
                    }} color="white" >Best rates. Lowest fees.
                    </Typography>
                    <Spacing variant="betweenSections" />
                    <Typography weight="bold" style={{
                        fontSize: '40px',
                        // fontFamily: 'Poppins'
                    }} color="white" >Fastest transfers.
                    </Typography>
                    <Spacing variant="betweenSections" />
                    <Typography weight="regular" style={{
                        fontSize: '18px',
                        // fontFamily: 'Poppins'
                    }} color="white" >We spark the financial revolution by connecting people
                        and money - across borders, currencies, and cultures.
                    </Typography>
                </div>
            </Container>
        </div>
    );
};

export default Drawer;