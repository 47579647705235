import React, { useEffect } from "react";
import Container from "../../../remitbee/components/container/Container";
import Typography from "../../../remitbee/components/typography/Typography";
import Spacing from "../../../remitbee/styles/spacing/Spacing";
import Box from "../../../remitbee/components/box/Box";
import Divider from "../../../remitbee/components/divider/Divider";
import Button from "../../../remitbee/components/button/Button";
import { useRouter } from 'next/router';
import theme from '../../../remitbee/theme/Theme';
import classes from './ForgotPassword.module.scss';
import { useMediaQuery } from "@mui/material";


const CheckEmail = ({ resetEmail,t }) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const router = useRouter();
    return (
        <>
            {
                !isMobile && <Container style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '75vh ',
                    justifyContent: 'center'
                }}
                    type='extrasmall'
                >
                    <div>
                        <Box display="flex" justifyContent="center">
                            <img id='email' src="/design-system/login/email.svg" alt="check email icon" />
                        </Box>
                        <Typography id='check_your_email' variant='h1' weight='semibold' color={theme.palette.primary.navy} align='center'>
                            {t('login:check_your_email')}
                        </Typography>
                        <Spacing variant='betweenHeaderToSubheader' />
                        <Box>
                            <Divider />
                            <Spacing variant='betweenSections' />
                        </Box>
                        <Typography id='weve_sent_you_a_reset_link' align="center">
                            {t('login:weve_sent_you_a_reset_link', {resetEmail})}
                        </Typography>
                        <Spacing variant='betweenSummaryCards' />
                        <Typography id='the_email_maybe_on_spam' align="center">
                        {t('login:the_email_maybe_on_spam')}
                        </Typography>
                        <Spacing variant='betweenSections' />
                        <Button fullWidth id='back_to_login' type='submit' onClick={() => router.push('/login')}>{t('login:back_to_login')}</Button>
                        <Spacing variant='betweenSectionsSmall' />
                        <Button fullWidth variant='outlined' id='back_to_homepage' type='submit' onClick={() => router.push('/')}>{t('login:back_to_homepage')}</Button>
                    </div>
                </Container>
            }
            {
                isMobile && <Spacing variant='containerVerticalPadding' >
                        <Box className={classes['rb-forgotPassword-mobileView']}>
                        <Box>
                            <Box display="flex" justifyContent="center">
                                <img id='email' src="/design-system/login/email.svg" alt="check email icon" />
                            </Box>
                            <Typography id='check_your_email' variant='h2' weight='semibold' color={theme.palette.primary.navy} align='center'>
                            {t('login:check_your_email')}
                            </Typography>
                            <Spacing variant='betweenSummaryCards' />
                            <Typography id='weve_sent_you_a_reset_link' align="center">
                            {t('login:weve_sent_you_a_reset_link', {resetEmail})}
                            </Typography>
                            <Spacing variant='betweenSummaryCards' />
                            <Typography id='the_email_maybe_on_spam' align="center">
                                {t('login:the_email_maybe_on_spam')}
                            </Typography>
                            <Spacing variant='betweenSections' />
                            </Box>
                            <Box>
                                <Button fullWidth id='back_to_login' type='submit' onClick={() => router.push('/login')}>{t('login:back_to_login')}</Button>
                                <Spacing variant='betweenButtons' />
                                <Button fullWidth variant='outlined' id='back_to_homepage' type='submit' onClick={() => router.push('/')}>{t('login:back_to_homepage')}</Button>
                            </Box>
                        </Box>
                </Spacing>
            }
        </>
    );
}



export default CheckEmail;